@import 'mixins';
@import 'colors';
@import 'vars';

$paddingWidth: 40px;

.advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cards {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include maxWidth {
    justify-content: center;
  }
}

.padding {
  width: $paddingWidth;
  min-width: $paddingWidth;
  height: 40px;
}

.card {
  $cardWidth: 270px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: $cardWidth;
  min-width: $cardWidth;
  height: 240px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 32px;
  box-shadow: 12px 18px 20px -7px #CBCCDB;
  background: linear-gradient(0deg, rgba(82, 85, 134, 0.9), rgba(82, 85, 134, 0.9)), url('../../assets/images/card_bg.jpeg');

  &:first-child {
    margin-left: $paddingWidth;
  }

  &__star {
    width: 100px;
  }

  &__title {
    font-family: Montserrat-Bold;
    letter-spacing: 0.08em;
    font-size: 20px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    color: $white;
  }

  &__icon {
    margin-bottom: 30px;
  }
}


.title {
  color: $red;
  font-family: Montserrat-Bold;
  font-size: 5vw;
  margin: 70px 20px 30px;
  line-height: 100%;

  @include maxWidth {
    font-size: 66px;
  }

  @include forPhone {
    font-size: 32px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  &_sub {
    color: $purpure1;
  }
}
