@import 'colors';
@import 'mixins';

.store_buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 150px;
  margin-bottom: 150px;

  @include forPhone {
    margin: 60px 40px 90px;
  }

  &__container {
    max-width: 700px;
  }

  &__left_align {
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
  }
}

.title {
  font-family: Montserrat-Bold;
  font-size: 4vw;
  color: $red;
  text-align: center;

  @include maxWidth {
    font-size: 37px;
  }

  @include forPhone {
    font-size: 26px;
  }
}

.content {
  margin-top: 20px;
  font-size: 3vw;
  margin-bottom: 20px;
  text-align: center;

  @include maxWidth {
    font-size: 30px;
  }

  @include forPhone {
    font-size: 16px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button {
  margin-right: 10px;
}

.button_image {
  width: 16vw;

  @include maxWidth {
    width: 250px;
  }

  @include forPhone {
    width: 150px;
  }
}
