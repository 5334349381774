@import 'colors';
@import 'mixins';
@import 'vars';

.questions {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  @include forPhone {
    padding-bottom: 50px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $maxScreenWidth;
    padding: 80px 40px;

    @include forPhone {
      @include phonePadding;
    }
  }

  &__background {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 47vw;
    background-color: $purpure;
    z-index: -1;

    @include forPhone {
      height: 265px;
    }

    @include maxWidth {
      height: 611px;
    }

    &__image_container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      max-width: 1280px;
    }

    &__image {
      width: 100%;
      object-fit: cover;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      width: 50vw;

      @include maxWidth {
        width: 640px;
      }

      @include forPhone {
        max-width: 250px;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
}

.title {
  color: $white;
  font-family: Montserrat-Bold;
  font-size: 5vw;
  margin-bottom: 40px;
  line-height: 100%;

  @include maxWidth {
    font-size: 66px;
  }

  @include forPhone {
    font-size: 32px;
    margin-bottom: 8px;
  }

  &_sub {
    color: $red;
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;

  @include forPhone {
    margin-top: 85px;
  }
}
