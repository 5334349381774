@import 'mixins';
@import 'colors';
@import 'vars';

.about {
  display: flex;
  justify-content: center;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: $maxScreenWidth;
    padding-top: 120px;

    @include forPhone {
      padding: 40px 20px 0px;
    }
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 25px;
  margin-top: 50px;
  margin-bottom: 30px;
  max-width: 700px;

  @include forPhone {
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 0;
  }
}

.title {
  color: $red;
  font-family: Montserrat-Bold;
  text-align: center;
  font-size: 5vw;

  @include maxWidth {
    font-size: 66px;
  }

  @include forPhone {
    font-size: 32px;
  }

  &__url {
    color: $purpure1;
  }
}
