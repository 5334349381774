@import 'vars';
@import 'fonts';
@import 'colors';

body, html {
  margin: 0;
  padding: 0;
  font-family: Montserrat-Regular;
  font-size: 16px;
  color: $black;
}

li {

  &::marker {
    color: $red;
  }
}

a {
  text-decoration: none;
}
