@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'),
  url('../assets/fonts/Montserrat-Bold.woff2') format('woff2'),
  url('../assets/fonts/Montserrat-Bold.woff') format('woff'),
  url('../assets/fonts/Montserrat-Bold.ttf') format('ttf'),
  url('../assets/fonts/Montserrat-Bold.eot') format('eot');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'),
  url('../assets/fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../assets/fonts/Montserrat-Regular.woff') format('woff'),
  url('../assets/fonts/Montserrat-Regular.ttf') format('ttf'),
  url('../assets/fonts/Montserrat-Regular.eot') format('eot');
}
