@import 'colors';
@import 'mixins';
@import 'vars';

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  background: $purpure;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $maxScreenWidth;
  }
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 48px;
  position: relative;

  @include paddingHorizontal(40px);

  &__left  {
    font-family: Montserrat-Regular;
    color: $white;
    font-size: 5.5vw;
    line-height: 0.95;
    max-width: 100%;

    @include maxWidth {
      font-size: 70px;
      height: 200px;
    }

    &_url {
      color: $red;
      font-family: 'Montserrat-Bold';
    }

    &_sub {
      margin-top: 25px;
      font-size: 2.5vw;
      line-height: 1.4;

      @include maxWidth {
        font-size: 30px;
      }

      @include forPhone {
        margin-top: 10px;
        font-size: 20px;
      }
    }

    &_content {
      //position: absolute;
      max-width: 730px;
    }
  }

  &__right {

    &_logo {
      width: 286px;

      @include forPhone {
        display: none;
      }
    }
  }
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;

  @include paddingHorizontal(40px);
  @include forPhone {
    flex-direction: column-reverse;
  }

  &__left  {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 590px;
    font-size: 26px;
    font-family: 'Montserrat-Bold';
    color: white;
    padding-right: 20px;

    @include forPhone {
      font-family: Montserrat-Regular;
      font-size: 22px;
      padding-right: 0;
    }
  }

  &__right {
    &_image {
      width: 50vw;

      @include maxWidth {
        width: 590px;
      }

      @include forPhone {
        width: 100%;
      }
    }
  }
}

.buttons {
  margin-top: 2vw;

  @include maxWidth {
    margin-top: 40px;
  }

  @include forPhone {
    margin-top: 20px;
  }
}
