@import 'vars';

@mixin paddingHorizontal($val) {
  padding-left: $val;
  padding-right: $val;
}

@mixin maxWidth {
  @media (min-width: $maxScreenWidth) { @content; }
}

@mixin forPhone {
  @media (max-width: $phoneWidth) { @content; }
}

@mixin phonePadding {
  padding: 40px 20px 40px;
}
