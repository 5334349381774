@import 'colors';
@import 'vars';
@import 'mixins';

.question_item {
  background-color: $white;
  width: 100%;
  max-width: 996px;
  line-height: 24px;
  margin: 8px;
  border: 1px solid $purpure;
  border-radius: 4px;
  user-select: none;
  overflow: hidden
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  color: $purpure1;
  font-family: Montserrat-Bold;
  cursor: pointer;

  @include paddingHorizontal(10px);

  &:hover {
    background-color: $gray;
  }

  &__arrow_icon__open {
    transform: rotate(180deg) translateY(10px);
  }
}

.content {
  padding: 16px;
  white-space: pre-line;

  @include paddingHorizontal(10px);
}
