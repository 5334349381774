@import 'colors';
@import 'mixins';
@import 'vars';

.header_panel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 50;
  min-height: 44px;
  height: 92px;
  display: flex;
  justify-content: center;
  background: $purpure;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

  @include forPhone {
    height: 76px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 4px;
    max-width: $maxScreenWidth;
    width: $maxScreenWidth;

    @include forPhone {
      height: 76px;
    }

    @include paddingHorizontal(40px);

    @media (max-width: 720px) {
      justify-content: center;
    }

    @media (max-width: 720px) {
      @include paddingHorizontal(0);
    }

    &__head {
      &_text {
        color: $white;
        font-family: 'Montserrat-Bold';
        font-size: 2.7vw;
        line-height: 0.95;
        max-width: 100%;
      }

      @media (max-width: 720px) {
        display: none;
      }
    }


    &__buttons {
      & > div {
        margin: 0;
      }

      img {
        width: 180px;

        @media (max-width: 720px) {
          width: 150px;
        }
      }
    }
  }
}
