@import 'mixins';

.divider {
  width: 100%;
  height: 720px;
  position: relative;

  @include forPhone {
    height: 220px;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

.ellipse {
  height: 50vw;
  position: absolute;
  top: -25vw;

  @include maxWidth {
    height: 718px;
    top: -369px;
  }

  @include forPhone {
    height: 290px;
    top: -145px;
  }
}
