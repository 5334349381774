@import 'colors';
@import 'mixins';

.text_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  max-width: 655px;

  @include forPhone {
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  &__reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;

    @include forPhone {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.title {
  font-family: Montserrat-Bold;
  font-size: 26px;
  color: $red;
  margin-bottom: 8px;

  @include forPhone {
    font-size: 14px;
  }
}

.text {
  max-width: 488px;
  line-height: 24px;

  @include forPhone {
    font-size: 14px;
  }
}

.icon {
  height: 138px;
  margin-left: 10px;
  margin-right: 10px;

  @include forPhone {
    height: 20vw;
  }
}
