@import 'vars';
@import 'colors';

.main_page {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.colored {
  color: $purpure1;
}
