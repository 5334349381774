@import 'colors';
@import 'mixins';
@import 'vars';

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $gray2;
  color: $white;
  padding-bottom: 70px;
  margin-top: 200px;

  @include forPhone {
    margin-top: 140px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $maxScreenWidth;

    @include forPhone {
      flex-direction: column;
      justify-content: center;
    }
  }
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  transform: translateY(-50%);

  &__img {
    width: 14vw;
    position: relative;
    margin: 10px;

    @include maxWidth {
      width: 183px;
    }

    @include forPhone {
      margin: 5px;
    }

    &_top {
      transform: translateY(-5%);
    }

    &_bottom {
      transform: translateY(5%);
    }
  }
}

.data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;

  @include forPhone {
    align-content: space-between;
    justify-content: space-between;
  }

  &__title {
    font-family: Montserrat-Bold;
    font-size: 20px;
    margin: 10px 0;
  }

  &__text {
    font-size: 15px;
    line-height: 1.4;
    margin: 0;
    display: block;
    color: inherit;

    &__handing {
      font-size: 12px;
      color: $gray4;
    }
  }

  &__item {
    font-size: 12px;
    width: calc(25% - 21px);
    min-width: 210px;
    margin-bottom: 20px;

    @include forPhone {
      width: 100%;
    }
  }
}

.company_info {
  font-size: 12px;
  margin-left: 20px;
  margin-right: 20px;
  color: $gray4;
}
