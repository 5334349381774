@import 'mixins';
@import 'colors';
@import 'vars';

.about {
  display: flex;
  justify-content: center;
  width: 100%;
  background-image: url('../../assets/images/abou_bg.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 70vw;

  @media (max-width: 1000px) {
    background-image: none;
  }

  @include maxWidth {
    background-size: auto 930px;
  }


  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $maxScreenWidth;
    padding: 0px 40px 90px;

    @include forPhone {
      @include phonePadding;
    }
  }
}

.first_item_container {

  @include forPhone {
    align-self: flex-start;
  }
}

.items {
  margin-left: 100px;

  @include forPhone {
    margin-left: 0;
    align-items: center;
  }

  @media (max-width: 1158px) {
    margin-left: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;

    @include forPhone {
      align-items: center;
    }
  }
}
