@import 'mixins';
@import 'colors';
@import 'vars';

.customer {
  display: flex;
  justify-content: center;
  width: 100%;
  background: url('../../assets/images/customer_bg.png') no-repeat right;
  background-size: auto 55vw;
  height: 85vw;

  @include forPhone {
    background: none;
    height: auto;
  }

  @include maxWidth {
    background-size: auto 718px;
    height: 950px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: $maxScreenWidth;
    padding: 120px 40px 160px;

    @include forPhone {
      flex-direction: column;
      justify-content: center;

      @include phonePadding;
    }
  }
}

.title {
  color: $red;
  font-family: Montserrat-Bold;
  font-size: 5vw;
  margin-bottom: 40px;
  line-height: 100%;

  @include maxWidth {
    font-size: 66px;
  }

  @include forPhone {
    font-size: 32px;
    margin-bottom: 8px;
  }

  &_sub {
    color: $purpure1;
  }
}

.features_list {
  font-family: Montserrat-Regular;
  color: $black;
  padding: 20px 20px 20px;
  font-size: 26px;

  @include forPhone {
    font-size: 16px;
  }

  li {
    margin-bottom: 16px;
  }
}

.right {
  display: flex;
  justify-content: center;
  padding-top: 17vw;

  @include maxWidth {
    padding-top: 292px;
  }

  @include forPhone {
    padding-top: 0;
  }

  &__image {
    width: 45vw;

    @include maxWidth {
      width: 590px;
    }

    @include forPhone {
      display: none;
    }
  }
}

.mobileButtons {
  display: none;
  flex-direction: column;
  padding: 20px;
  max-width: 320px;

  @include forPhone {
    display: flex;
  }

  &__title {
    color: $red;
    font-family: Montserrat-Bold;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 16px;
    text-align: center;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &__content {
      display: flex;
      flex-direction: column;
    }
  }

  &__button {
    padding: 4px;
  }
}
