@import 'colors';
@import 'mixins';

.content {
  text-align: center;
  font-size: 1.4vw;
  line-height: 150%;
  margin-bottom: 20px;

  @include maxWidth {
    font-size: 16px;
  }

  @include forPhone {
    font-family: Montserrat-Bold;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &_colored {
    font-family: Montserrat-Bold;
    color: $red;
  }
}

.title {
  color: $red;
  font-family: Montserrat-Bold;
  text-align: center;
  font-size: 2vw;
  margin-bottom: 20px;

  @include maxWidth {
    font-size: 26px;
  }

  @include forPhone {
    font-size: 22px;
    margin-bottom: 8px;
  }

  &_colored {
    color: $purpure1;
  }
}

