@import 'vars';
@import 'mixins';
@import 'colors';

.cookies_alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2147483647 !important;
  display: flex;
  background-color: rgba(255, 255, 255, 0.85);
  border-top: 1px solid rgba(154, 156, 175, 0.7);
  min-height: 76px;
  padding: 16px 5% 24px 5%;

  &__title {
    font-family: Montserrat-Bold;
    font-size: 16px;
    line-height: 2;
    color: $black2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 420px) {
      flex-wrap: wrap;
    }

    &__message {
      margin-right: 18px;
    }

    &__link {
      text-decoration: underline;
      padding-left: 10px;
      color: $blue;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 2;
      min-width: 60px;
      cursor: pointer;
      height: 44px;
      min-height: 44px;
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 56px;
      background-color: $purpure1;

      @media (max-width: 420px) {
        margin-top: 12px;
      }

      &_text {
        color: white;
      }
    }
  }
}
