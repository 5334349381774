@import 'colors';
@import 'mixins';


.card_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  max-width: 794px;
  min-width: 520px;
  width: 100%;
  margin: 80px;
  background-color: $white;
  color: $purpure1;

  @include forPhone {
    margin: 40px 20px;
    padding: 16px;
    min-width: 0;
  }
}
