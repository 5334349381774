$white: #FFF;

$black: #333333;
$black2: #353641;

$gray: #F2F2F2;
$gray2: #333333;
$gray3: #9a9caf;
$gray4: #6f6f6f;

$purpure: #3F2F7D;
$purpure1: #525586;
$purpure2: #f6f6ff;

$blue: #104c97;

$red: #D6533E;
