@import 'vars';
@import 'mixins';
@import 'colors';

.market_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat-Regular;
  margin-top: 128px;
  line-height: 1.6;

  @include forPhone {
    margin-top: 112px;
  }
}

.market_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: $maxScreenWidth;

  &__title {
    padding: 18px;
    text-align: center;
    font-size: 48px;
    font-family: Montserrat-Bold;

    @include forPhone {
      font-size: 32px;
    }

    &_greeting {
      color: $red;
    }

    &_name {
      color: $purpure1;
    }
  }

  &__location {
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @include paddingHorizontal(20px);

    @include forPhone {
      margin-top: 24px;
      justify-content: flex-start;
    }

    &_address {
      padding: 18px;
      font-size: 20px;
      margin-right: 32px;

      @include forPhone {
        padding: 12px 0 12px 0;
        font-size: 18px;
      }

      &__label {
        color: $black;
        font-size: 32px;
        font-family: Montserrat-Bold;

        @include forPhone {
          font-size: 24px;
        }
      }
    }

    &_map {

    }
  }
}

.market_work {
  margin-top: 48px;
  color: $purpure1;
  max-width: $maxScreenWidth;

  @include paddingHorizontal(20px);

  @include forPhone {
    margin-top: 28px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1000px;
    max-width: 100%;

    @include forPhone {
      justify-content: flex-start;
      width: 100%;
    }
  }

  &__time {
    font-size: 28px;
    font-weight: 500;

    @include forPhone {
      font-size: 18px;
    }
  }

  &__delivery_time {
    font-size: 28px;

    @include forPhone {
      margin-top: 20px;
      font-size: 18px;
    }

    &__comment {
      font-size: 20px;
      color: $black;
      text-align: center;
      margin-top: 24px;

      @include forPhone {
        font-size: 14px;
      }
    }
  }
}

.market_app {
  margin-top: 32px;
  max-width: $maxScreenWidth;

  @include paddingHorizontal(20px);

  &__title {
    margin-bottom: 18px;
    font-family: Montserrat-Bold;
    font-size: 28px;
    text-align: center;
    color: $red;
    max-width: 720px;

    @include forPhone {
      font-size: 24px;
    }
  }

  > div {
    margin: 24px 0 0;
  }
}

.market_managers {
  margin-top: 72px;
  max-width: $maxScreenWidth;

  @include paddingHorizontal(20px);

  @include forPhone {
    margin-top: 56px;
  }

  &__title {
    font-family: Montserrat-Bold;
    font-size: 44px;
    text-align: center;
    color: $red;

    @include forPhone {
      font-size: 28px;
    }
  }

  &__list {
    margin-top: 64px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include forPhone {
      margin-top: 48px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 240px;
    margin: 0 16px 24px 16px;

    &_img {
      width: 156px;
      height: 156px;
      border-radius: 50%;
    }

    &_title {
      margin-top: 10px;
      text-align: center;
      font-size: 22px;
      height: 70px;

      @include forPhone {
        font-size: 18px;
      }
    }
  }
}

.market_how_work {
  margin-top: 72px;
  max-width: 1080px;
  font-size: 20px;

  @include forPhone {
    margin-top: 36px;
    font-size: 16px;
  }

  @include paddingHorizontal(20px);

  &__title {
    font-family: Montserrat-Bold;
    font-size: 44px;

    @include forPhone {
      font-size: 28px;
    }

    &_text {
      color: $red;
      text-align: center;
    }

    &_name {
      color: $purpure1;
      text-align: center;
    }
  }

  &__desc {
    margin-top: 64px;

    @include forPhone {
      margin-top: 32px;
    }

    &_title {
      display: flex;
      align-items: center;

      @include forPhone {
        display: block;
      }
    }

    &_link {
      padding-left: 10px;
      text-decoration: underline;
    }

    &_num {
      font-size: 36px;
      margin-right: 18px;
      color: $purpure;
      border: 3px solid $purpure;
      border-radius: 50%;
      width: 54px;
      height: 54px;
      min-width: 54px;
      min-height: 54px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: Montserrat-Bold;

      @include forPhone {
        font-size: 24px;
        margin-right: 18px;
        border: 2px solid $purpure;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
      }
    }

    &_list {
      margin-left: 48px;

      @include forPhone {
        margin-left: 0;
      }
    }
  }

  &__padding {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 256px;

    @include forPhone {
      margin-left: 0;
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: left;
    }
  }

  &__screens {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &_item {
      margin: 0 24px 24px 24px;
      max-width: 320px;

      @include forPhone {
        margin: 0 0 24px 0;
      }

      &_img {
        max-width: 320px;
      }
    }
  }
}

.market_how_order {
  margin-top: 48px;
  max-width: 1080px;
  font-size: 20px;

  @include paddingHorizontal(20px);

  @include forPhone {
    font-size: 16px;
  }

  &__title {
    font-family: Montserrat-Bold;
    font-size: 44px;

    @include forPhone {
      font-size: 28px;
    }

    &_text {
      color: $red;
      text-align: center;
    }

    &_name {
      color: $purpure1;
      text-align: center;
    }
  }

  &__step {
    margin-top: 48px;

    @include forPhone {
      margin-top: 32px;
    }

    &__title {
      color: $red;
      font-size: 36px;

      @include forPhone {
        font-size: 24px;
      }
    }

    &__desc {
      margin-top: 24px;
    }

    &__comment {
      margin-top: 24px;
      margin-left: 20px;

      @include forPhone {
        margin-left: 0;
      }
    }
  }

  &__delivery_info {
    margin-top: 48px;

    &__title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }

    ol {
      padding-left: 21px;
    }

    ol li::marker {
      color: $black;
    }
  }
}

.market_footer {
  margin-top: 84px;
  background-color: $gray2;
  width: 100%;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  @include forPhone {
    margin-top: 48px;
  }

  &__content {
    padding: 32px 24px 128px 24px;
  }

  &__title {
    text-align: center;
    font-size: 36px;
    font-family: Montserrat-Bold;

    @include forPhone {
      font-size: 24px;
    }
  }

  &__contact_label {
    margin-top: 24px;
    text-align: center;
  }

  &__contact {
    margin-top: 24px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    @include forPhone {
      margin-top: 16px;
    }
  }

  &__phone, &__email {
    margin-top: 18px;
    font-size: 24px;
    line-height: 1.4;
    display: flex;
    align-items: center;

    @media (min-width: 760px) {
      margin: 18px 48px 0 48px
    }

    @include forPhone {
      font-size: 18px;
    }

    &_icon {
      width: 40px;
      height: 40px;
      margin-right: 18px;

      @include forPhone {
        width: 28px;
        height: 28px;
      }
    }

    a {
      color: inherit;
    }
  }
}
